<template>
    <div class="container__tag">
        <div class="d-flex align-items-center">
            <label v-if="$props.config.isShowLable" for="" style="white-space: nowrap" class="mb-0 mr-2">フリーワード</label>
            <b-form-tags
                separator="#"
                :tag-class="`custom__tags ${$props.checkPre}`"
                input-id="tags-validation"
                :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                v-model="$props.config.arrayTag"
                :tag-validator="tagValidator"
                tag-variant="primary"
                :addButtonText="$t('btn.addTag')"
                size="md"
                :placeholder="$props.config.placeholder ? $props.config.placeholder : ''"
                invalid-tag-text=""
                duplicate-tag-text=""
            ></b-form-tags>
        </div>
    </div>
</template>

<script>
export default {
    name: 'keyword-tag',
    props: {
        checkPre: {
            type: String,
            default() {
                return '';
            }
        },
        config: {
            type: Object,
            default() {
                return {
                    error: false,
                    arrayTag: [],
                    placeholder: '',
                    isShowLable: false
                };
            }
        }
    },
    data() {
        return {};
    },
    computed: {
        displayedTags() {
            // Thêm "#" vào trước mỗi tag để hiển thị
            return this.$props.config.arrayTag.map((tag) => '#' + tag);
        }
    },
    watch: {},
    methods: {
        tagValidator(tag) {
            let config = this.$props.config;
            if (tag.length >= 255) {
                config.error = true;
            } else {
                config.error = false;
            }
            this.$emit('update:config', config);
            return tag.length < 255;
        }
    }
};
</script>

<style lang="scss" scoped>
.container__tag {
    .custom__tags {
        font-size: 14.4px;
    }
}
:deep(button.btn.b-form-tags-button.py-0.btn-outline-secondary) {
    width: 65px;
}
</style>
